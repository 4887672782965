import { ref, reactive } from "vue";
import { useMessage } from "@/utils";
import type { CardItemInfo } from "../type";
import { getPricingList } from "@/api/svPremium";
import {
  freePriorityList,
  freePlanDescription,
  freePriorityDescription,
  starterPriorityList,
  starterPlanDescription,
  starterPriorityDescription,
  standardPriorityList,
  proPriorityList,
  ultraPriorityList,
  standardPlanDescription,
  proPlanDescription,
  ultraPlanDescription,
  standardPriorityDescription,
  proPriorityDescription,
  ultraPriorityDescription,
} from "../constants";
const cardList: CardItemInfo[] = reactive([
  // {
  //   name: "Free",
  //   title: "Free",
  //   hasDiscount: false,
  //   monthlyDiscount: "",
  //   monthlyPrice: "$0",
  //   packageCode: 400101,
  //   planDescription: freePlanDescription,
  //   buttonText: "Subscribe now",
  //   priorityDescription: freePriorityDescription,
  //   priorityList: freePriorityList,
  //   paymentDescription: "Billed monthly",
  //   cycleType: "month",
  //   children: [
  //     {
  //       couponCode: "",
  //       hasDiscount: false,
  //       monthlyDiscount: "$0",
  //       monthlyPrice: "$0",
  //       packageCode: 400101,
  //     },
  //   ],
  // },
  {
    name: "Starter",
    title: "Starter",
    hasDiscount: false,
    monthlyDiscount: "",
    monthlyPrice: "",
    packageCode: 0,
    planDescription: starterPlanDescription,
    buttonText: "Subscribe now",
    priorityDescription: starterPriorityDescription,
    priorityList: starterPriorityList,
    paymentDescription: "Billed monthly",
    cycleType: "month",
    children: [
    ],
  },
  {
    name: "Standard",
    title: "Standard",
    hasDiscount: false,
    yearlyPrice: "$99",
    monthlyDiscount: "",
    monthlyPrice: "",
    packageCode: 0,
    planDescription: standardPlanDescription,
    buttonText: "Subscribe now",
    priorityDescription: standardPriorityDescription,
    priorityList: standardPriorityList,
    paymentDescription: "Billed monthly",
    cycleType: "month",
    children: [
    ],
  },
  {
    name: "Pro",
    title: "Pro",
    hasDiscount: false,
    yearlyPrice: "$99",
    monthlyDiscount: "",
    monthlyPrice: "",
    packageCode: 0,
    planDescription: proPlanDescription,
    buttonText: "Subscribe now",
    priorityDescription: proPriorityDescription,
    priorityList: proPriorityList,
    paymentDescription: "Billed monthly",
    cycleType: "month",
    children: [

    ],
  },
  {
    name: "Ultra",
    title: "Ultra",
    hasDiscount: false,
    yearlyPrice: "",
    monthlyDiscount: "",
    monthlyPrice: "Let's chat",
    packageCode: 1,
    planDescription: ultraPlanDescription,
    buttonText: "Contact us",
    priorityDescription: ultraPriorityDescription,
    priorityList: ultraPriorityList,
    paymentDescription: "Billed monthly",
    cycleType: "month",
    children: [

    ],
  },
]);
const freeTrial = ref(false);

const getPricingCardData = async () => {
  try {
    const { code, data } = await getPricingList();
    if (code === 0) {
      const { freeTrialBoolean, planList } = data;
      freeTrial.value = freeTrialBoolean;
      cardList.forEach(item => {
        if (item.name === "Free") return;
        item.children = []
      });
      planList.forEach((item) => {
        const target = cardList.find(i => i.name === item.subTag);
        if (target && target?.children)  {
          target.children.push({ 
            couponCode: item.couponCode,
            packageCode: item.packageCode,
            hasDiscount: item.hasDiscount,
            monthlyPrice: item.monthlyPrice,
            monthlyDiscount: item.monthlyDiscountedPrice,
          })
        }
      });

    }
  } catch (error) {
    const message = useMessage();
    message.error("Failed to fetch price list");
  }
};

const getCardInfo = (code: number) => {
  const allCards = cardList.reduce((a, c) => {
    const { children, ...base } = c;
    return a.concat(children?.map(p => ({ ...base, ...p})));
  }
  , [])
  const {cardInfo} = allCards.find(
    (item) => item.packageCode === code,
  );
  return cardInfo;
};

export function usePricingStore() {
  return {
    cardList,
    freeTrial,
    getPricingCardData,
    getCardInfo,
  };
}
